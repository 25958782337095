import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ICard } from '../../../models/card';
import { CommonModule } from '@angular/common';
import { formatCardNumbers } from '../../../utils/formatters';
import { LoaderComponent } from '../loader/loader.component';
import Swal from 'sweetalert2';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-cards',
  standalone: true,
  imports: [CommonModule, LoaderComponent],
  templateUrl: './cards.component.html',
  styleUrl: './cards.component.scss',
})
export class CardsComponent implements OnInit {
  public cards: ICard[] = [];
  public isLoading: boolean = true;
  public userHasRecurrentPlan: boolean = false;

  public formatCardNumbers = formatCardNumbers;
  constructor(private readonly userService: UserService) {}

  ngOnInit(): void {
    forkJoin([
      this.userService.getCards(),
      this.userService.getUserPlan(),
    ]).subscribe({
      next: (res) => {
        this.userHasRecurrentPlan = res[1].is_recurrent;

        this.cards = res[0].data
          ? res[0].data.map((card: ICard) => {
              card.is_main = card.id === res[1]?.subscription?.card?.id;
              return card;
            })
          : [];
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  deleteCard(cardId: string) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir esse cartão?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, excluir!',
      background: '#444',
      color: '#fff',
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.deleteCardById(cardId).subscribe({
          next: () => {
            this.cards = this.cards.filter((card) => card.id !== cardId);
          },
        });
      }
    });
  }
}
