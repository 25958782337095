import { Component } from '@angular/core';
import { LoaderComponent } from '../../../loader/loader.component';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { CardInformationsFormComponent } from '../card-informations-form/card-informations-form.component';

@Component({
  selector: 'app-change-main-card',
  standalone: true,
  imports: [LoaderComponent, CommonModule, CardInformationsFormComponent],
  templateUrl: './change-main-card.component.html',
  styleUrl: './change-main-card.component.scss',
})
export class ChangeMainCardComponent {
  public isLoading: boolean = false;

  constructor(private readonly router: Router) {}

  goBack() {
    this.router.navigate(['/cartoes']);
  }
}
