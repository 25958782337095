import { Routes } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { CardsComponent } from './components/cards/cards.component';
import { InformationComponent } from './components/information/information.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { HomeComponent } from './components/home/home.component';
import { AddCardComponent } from './components/billing/card/add-card/add-card.component';
import { ChangeMainCardComponent } from './components/billing/card/change-main-card/change-main-card.component';
import { AfterSignUpComponent } from './components/after-sign-up/after-sign-up.component';
import { AuthGuard } from './auth/auth.guard';

export const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canMatch: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent,

        pathMatch: 'full',
        //title: 'Home',
      },

      {
        path: 'cards',
        component: CardsComponent,
        //title: 'Cartoes'
      },

      {
        path: 'informacoes',
        component: InformationComponent,
        //title: 'Informações',
      },
    ],
  },
  {
    path: 'checkout/:id/:period',
    pathMatch: 'prefix',
    component: CheckoutComponent,
    canMatch: [AuthGuard],
    //title: 'Checkout',
  },
  {
    path: 'cobranca',
    canMatch: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'adicionar-cartao',
        pathMatch: 'full',
      },

      {
        path: 'adicionar-cartao',
        component: AddCardComponent,
        // title: 'Adicionar Cartão',
      },

      {
        path: 'mudar-cartao-principal',
        component: ChangeMainCardComponent,
        // title: 'Trocar Cartão Principal',
      },
    ],
  },
  {
    path: 'after-signup',
    title: 'Finalizar Cadastro',
    component: AfterSignUpComponent,
  },

  { path: '**', redirectTo: '' },
];
