<div class="flex flex-col">
  <div class="flex flex-col">
    <span class="text-error text-xs mt-4">* Campos obrigatórios</span>
    <details class="collapse bg-base-200">
      <summary class="collapse-title text-xl font-medium">
        Informação de cadastro
      </summary>
      <div class="collapse-content">
        <form
          [formGroup]="pagarmeClientForm"
          class="grid grid-cols-4 gap-4 items-end"
        >
          <div class="form-control col-span-full">
            <label class="label">
              <span class="label-text">Nome completo</span>
            </label>
            <input
              type="text"
              class="input input-bordered"
              formControlName="name"
              placeholder="Nome completo"
            />
          </div>

          <!-- <div class="form-control col-span-full">
            <label class="label">
              <span class="label-text">Gênero</span>
            </label>
            <select formControlName="gender" class="select select-bordered">
              <option value="" selected disabled hidden>Selecione</option>
              <option value="male">Masculino</option>
              <option value="female">Feminino</option>
            </select>
          </div>

          <div class="form-control col-span-1">
            <label class="label">
              <span class="label-text">Dia de nascimento</span>
            </label>
            <input
              type="text"
              mask="00"
              class="input input-bordered"
              formControlName="birth_day"
            />
          </div> -->

          <!-- <div class="form-control col-span-1">
            <label class="label">
              <span class="label-text">Mes de nascimento</span>
            </label>
            <input
              type="text"
              mask="00"
              class="input input-bordered"
              formControlName="birth_month"
            />
          </div>

          <div class="form-control col-span-2">
            <label class="label">
              <span class="label-text">Ano de nascimento</span>
            </label>
            <input
              type="text"
              mask="0000"
              class="input input-bordered"
              formControlName="birth_year"
            />
          </div> -->

          <div class="form-control col-span-full">
            <label class="label">
              <span class="label-text">Celular</span>
            </label>
            <input
              type="text"
              class="input input-bordered"
              formControlName="phone"
              mask="(00) 00000-0000"
            />
          </div>

          <div class="form-control col-span-full">
            <label class="label">
              <span class="label-text">CPF/CNPJ</span>
            </label>
            <input
              type="text"
              class="input input-bordered"
              formControlName="document"
              mask="CPF_CNPJ"
            />
          </div>
        </form>
      </div>
    </details>

    <span class="text-error text-xs mt-4">* Campos obrigatórios</span>
    <details class="collapse bg-base-200">
      <summary class="collapse-title text-xl font-medium">
        Endereço de cobrança
      </summary>
      <div class="collapse-content">
        <form
          [formGroup]="pagarmeAdressForm"
          class="grid grid-cols-1 md:grid-cols-2 gap-4"
        >
          <div class="form-control col-span-1">
            <label class="label">
              <span class="label-text">CEP</span>
            </label>
            <input
              type="text"
              class="input input-bordered"
              formControlName="cep"
              mask="00000-000"
            />
          </div>

          <div class="form-control col-span-1">
            <label class="label">
              <span class="label-text">Estado</span>
            </label>
            <select formControlName="state" class="select select-bordered">
              <option value="" selected disabled hidden>Selecione</option>
              <option *ngFor="let state of BRAZILIAN_STATES" [value]="state">
                {{ state }}
              </option>
            </select>
          </div>

          <div class="form-control col-span-1">
            <label class="label">
              <span class="label-text">Cidade</span>
            </label>
            <input
              type="text"
              class="input input-bordered"
              formControlName="city"
            />
          </div>

          <div class="form-control col-span-1">
            <label class="label">
              <span class="label-text">Bairro</span>
            </label>
            <input
              type="text"
              class="input input-bordered"
              formControlName="neighborhood"
            />
          </div>

          <div class="form-control col-span-1">
            <label class="label">
              <span class="label-text">Rua</span>
            </label>
            <input
              type="text"
              class="input input-bordered"
              formControlName="street"
            />
          </div>

          <div class="form-control col-span-1">
            <label class="label">
              <span class="label-text">Número</span>
            </label>
            <input
              type="text"
              class="input input-bordered"
              formControlName="number"
            />
          </div>

          <div class="form-control col-span-1 md:col-span-2">
            <label class="label">
              <span class="label-text">Complemento</span>
            </label>
            <input
              type="text"
              class="input input-bordered"
              formControlName="complement"
            />
          </div>
        </form>
      </div>
    </details>

    <form
      (ngSubmit)="submit()"
      [formGroup]="creditCardForm"
      class="flex flex-col"
    >
      <div class="form-control my-2">
        <label class="label">
          <span class="label-text">Dados do Cartão</span>
        </label>

        <div class="join join-vertical">
          <input
            class="input input-bordered join-item"
            type="text"
            formControlName="number"
            mask="0000 0000 0000 0000"
            placeholder="1234 5678 9012 3456"
          />

          <div class="join join-item join-horizontal">
            <input
              class="input input-bordered w-full join-item"
              type="text"
              formControlName="expiration_date"
              mask="00/00"
              placeholder="MM/AA"
            />

            <input
              class="input input-bordered w-full join-item"
              type="text"
              formControlName="cvv"
              mask="000"
              placeholder="000"
            />
          </div>
        </div>
      </div>

      <div class="form-control my-2">
        <label class="label">
          <span class="label-text">Nome do titular do cartão</span>
        </label>
        <input
          type="text"
          class="input input-bordered"
          formControlName="holder_name"
          placeholder="Nome completo"
        />
      </div>

      <div class="form-control my-2">
        <label class="label">
          <span class="label-text">CPF</span>
        </label>
        <input
          type="text"
          class="input input-bordered"
          formControlName="holder_document"
          mask="CPF_CNPJ"
          placeholder="000.000.000-00"
        />
      </div>
      <button
        type="submit"
        class="btn btn-primary my-4"
        [disabled]="
          creditCardForm.invalid ||
          pagarmeAdressForm.invalid ||
          pagarmeClientForm.invalid ||
          isLoading
        "
      >
        Finalizar compra
      </button>
    </form>

    <p class="text-xs text-gray-500 mt-4">
      Ao confirmar sua assinatura, você permite que a Vigente cobre você em
      referência a pagamentos futuros em conformidade com os termos da empresa.
      Você pode cancelar a assinatura quando quiser.
    </p>
  </div>
</div>
