import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

type NotificationError = {
  type: 'success' | 'error' | 'warning' | 'info';
  message: string;
  visible: boolean;
};
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private readonly _data = new BehaviorSubject<NotificationError | null>(null);

  get data(): Observable<NotificationError | null> {
    return this._data.asObservable();
  }

  sendData(data: NotificationError): void {
    this._data.next(data);
  }

  clearData(): void {
    this._data.next(null);
  }
}
