import { Component, Input } from '@angular/core';
import { CardService } from '../../../../services/card.service';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective } from 'ngx-mask';
import { ICardBodyRequest } from '../../../../../models/card';
import { Router } from '@angular/router';
import {
  isValidCardNumbers,
  isValidCNPJ,
  isValidCPF,
  isValidCVV,
} from '../../../../../utils/validators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-card-informations-form',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule, NgxMaskDirective],
  templateUrl: './card-informations-form.component.html',
  styleUrl: './card-informations-form.component.scss',
})
export class CardInformationsFormComponent {
  @Input({ required: true }) action!: 'add' | 'change';
  public isLoading: boolean = false;

  public cardInformationsForm = new FormGroup({
    number: new FormControl('', {
      validators: [Validators.required],
    }),
    holder_name: new FormControl('', {
      validators: [Validators.required],
    }),
    holder_document: new FormControl('', {
      validators: [Validators.required],
    }),
    exp_month: new FormControl('', {
      validators: [Validators.required, Validators.maxLength(2)],
    }),
    exp_year: new FormControl('', {
      validators: [Validators.required, Validators.maxLength(4)],
    }),
    cvv: new FormControl('', {
      validators: [Validators.required, Validators.maxLength(3)],
    }),
  });

  constructor(
    private readonly cardService: CardService,
    private readonly router: Router
  ) {}

  get holder_document() {
    return this.cardInformationsForm.get('holder_document');
  }

  get number() {
    return this.cardInformationsForm.get('number');
  }

  get cvv() {
    return this.cardInformationsForm.get('cvv');
  }

  submitForm() {
    if (
      !isValidCNPJ(String(this.holder_document?.value)) &&
      !isValidCPF(String(this.holder_document?.value))
    ) {
      this.cardInformationsForm.get('holder_document')?.setErrors({
        notValid: true,
      });
      return;
    }

    if (!isValidCardNumbers(String(this.number?.value))) {
      this.cardInformationsForm.get('number')?.setErrors({
        notValid: true,
      });
      return;
    }

    if (!isValidCVV(String(this.cvv?.value))) {
      this.cardInformationsForm.get('cvv')?.setErrors({
        notValid: true,
      });
      return;
    }

    this.isLoading = true;
    const cardInformations: ICardBodyRequest = {
      cvv: this.cardInformationsForm.value.cvv!,
      exp_month: +this.cardInformationsForm.value.exp_month!,
      exp_year: +this.cardInformationsForm.value.exp_year!,
      holder_document: this.cardInformationsForm.value.holder_document!,
      holder_name: this.cardInformationsForm.value.holder_name!,
      number: this.cardInformationsForm.value.number!,
    };
    if (this.action === 'add') {
      this.cardService.addNewCard(cardInformations).subscribe({
        next: () => {
          void Swal.fire({
            icon: 'success',
            iconColor: '#22c55e',
            title: 'Cartão adicionado com sucesso',
            text: 'Deseja voltar para a tela de cartões?',
            showCancelButton: true,
            cancelButtonColor: '#3b82f6',
            cancelButtonText: 'Não, adicionar novo cartão',
            confirmButtonColor: '#22c55e',
            confirmButtonText: 'Sim',
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/cartoes']);
              return;
            }

            this.cardInformationsForm.reset();
          });
        },
        complete: () => {
          this.isLoading = false;
        },
      });

      return;
    }

    this.cardService.changeMainCard(cardInformations).subscribe({
      next: () => {
        this.router.navigate(['/cartoes']);
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }
}
