import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';
import { UserService } from '../../services/user.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-after-sign-up',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, NgxMaskDirective],
  templateUrl: './after-sign-up.component.html',
  styleUrl: './after-sign-up.component.scss',
})
export class AfterSignUpComponent {
  public isLoading: boolean = false;
  public afterSignUpForm = new FormGroup({
    name: new FormControl('', {
      validators: [Validators.required],
    }),
    email: new FormControl(
      { value: '', disabled: true },
      {
        validators: [Validators.required, Validators.email],
      }
    ),
    phone: new FormControl('', {
      validators: [Validators.required],
    }),
  });

  constructor(
    private readonly userservice: UserService,
    private readonly keycloakService: KeycloakService
  ) {
    this.keycloakService.loadUserProfile().then((user) => {
      this.afterSignUpForm.patchValue({
        email: user.email,
      });
    });
  }

  onSubmit() {
    this.isLoading = true;
    this.userservice
      .CreateUserAfterSignUp({
        email: this.afterSignUpForm.get('email')!.value!,
        name: this.afterSignUpForm.get('name')!.value!,
        phone: this.afterSignUpForm.get('phone')!.value!,
      })
      .subscribe({
        next: () => {
          window.location.href = `${window.location.origin}`;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }
}
