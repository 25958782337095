import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { UserService } from '../../services/user.service';
import { forkJoin, Subscription } from 'rxjs';
import { IUserPlan } from '../../../models/plan';
import { Chart, registerables } from 'chart.js';
import { CommonModule } from '@angular/common';
import { PlanService } from '../../services/plan.service';
import { LoaderComponent } from '../loader/loader.component';
import { ToastComponent } from '../toast/toast.component';
import { formatCurrency } from '../../../utils/formatters';
import { environment } from '../../../environments/environment';
import { INotification } from '../../../models/user';
import { NgScrollbarModule } from 'ngx-scrollbar';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    BaseChartDirective,
    CommonModule,
    LoaderComponent,
    ToastComponent,
    NgScrollbarModule,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public env = environment;
  public userPlanData: IUserPlan | null = null;
  public notifications: INotification[] = [];
  public paymentoInformation: {
    amount: number;
    status: string;
    payment_method: string;
  } = {
    amount: 0,
    status: '',
    payment_method: '',
  };
  public userStorageData = {
    ups: { used: 0, total: 0 },
    store: { used: 0, total: 0 },
  };

  public upChart!: Chart;
  public storeChart!: Chart;
  public barChartData: ChartConfiguration['data'] = {
    datasets: [],
  };
  public barChartOptions: ChartConfiguration['options'] = {
    borderColor: 'transparent',
  };

  public formatCurrency = formatCurrency;

  constructor(
    private readonly userService: UserService,
    private readonly planService: PlanService,
    private el: ElementRef
  ) {
    Chart.register(...registerables);
  }

  ngOnInit(): void {
    this.userService.getNotifications().subscribe({
      next: (data) => {
        this.notifications = data;
      },
    });
    const userplan = this.userService.getUserPlan().subscribe({
      next: (data) => {
        this.userPlanData = data;

        if (this.userPlanData.expiration_date != null) {
          if (this.diffInDays(this.userPlanData.expiration_date) <= 0) {
            this.userPlanData.payment_status = 'expired';
          }
        }

        if (data.plan) {
          const fork = forkJoin([
            this.userService.storageUsed(),
            this.userService.farmsCreated(),
            this.planService.getPlanIcon(data.plan.id),
          ]).subscribe({
            next: (results) => {
              const svgContainer =
                this.el.nativeElement.querySelector('#svgIconPlan');
              svgContainer.innerHTML = results[2];
              const svg =
                this.el.nativeElement.querySelector('#svgIconPlan svg');
              svg.classList.add('w-full', 'h-full');

              this.userStorageData = {
                store: {
                  used: results[0].total,
                  total: data.plan.max_documents ?? 0,
                },
                ups: {
                  used: results[1].total,
                  total: data.plan.max_documents ?? 0,
                },
              };
              this.renderChart({
                store: {
                  total: data.plan.max_documents ?? 0,
                  used: results[0].total,
                },
                up: {
                  total: data.plan.max_documents ?? 0,
                  used: results[1].total,
                },
              });
            },
          });
          this.subscriptions.push(fork);
          const paymentInfos = this.planService.paymentInformation().subscribe({
            next: (payment) => {
              this.paymentoInformation.amount = payment?.object?.amount ?? 0;
              this.paymentoInformation.status = payment?.object?.status;
              switch (payment.payment_method) {
                case 'credit_card':
                  this.paymentoInformation.payment_method = 'Cartão de Crédito';
                  break;

                case 'boleto':
                  this.paymentoInformation.payment_method = 'Boleto';
                  break;

                case 'pix':
                  this.paymentoInformation.payment_method = 'Pix';
                  break;
              }
            },
          });

          this.subscriptions.push(userplan, paymentInfos);
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  diffInDays(target: string | undefined) {
    if (!target) return 0;
    const targetDate = new Date(target);
    const currentDate = new Date();

    // Obtém os tempos em milissegundos
    const targetTime = targetDate.getTime();
    const currentTime = currentDate.getTime();
    const diffInMilliseconds = targetTime - currentTime;

    // Converte a diferença em dias
    const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
    return diffInDays;
  }

  renderChart(data: {
    store: { used: number; total: number };
    up: { used: number; total: number };
  }) {
    this.upChart?.destroy();
    this.storeChart?.destroy();

    this.upChart = new Chart('upChart', {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: [data.up.used, data.up.total - data.up.used],
            backgroundColor: ['#22c55e', 'rgba(0, 0, 0, .5)'],
          },
        ],
      },
      options: this.barChartOptions,
    });

    this.storeChart = new Chart('storeChart', {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: [data.store.used, data.store.total - data.store.used],
            backgroundColor: ['#22c55e', 'rgba(0, 0, 0, .5)'],
          },
        ],
      },
      options: this.barChartOptions,
    });
  }
}
