import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ICoupon, IPlan } from '../../models/plan';
import { catchError, from, switchMap, throwError } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import {
  IAddress,
  IAddressRequestBody,
  IPagarmeCustomer,
  IPagarmeCustomerRequestBody,
} from '../../models/user';

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  constructor(
    private readonly http: HttpClient,
    private readonly keycloakService: KeycloakService
  ) {}

  getPlanById(planId: number) {
    return this.http.get<IPlan>(`${environment.apiUrl}/plans/${planId}`);
  }

  getPlanIcon(planId: number) {
    return this.http.get(`${environment.apiUrl}/plans/${planId}/icon`, {
      responseType: 'text',
    });
  }

  getCoupon(couponCode: string) {
    return this.http.get<ICoupon>(
      `${environment.apiUrl}/coupons/code/${couponCode}`
    );
  }

  paymentInformation() {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((user: any) =>
        this.http.get<any>(
          `${environment.apiUrl}/pagarme/subscriptions/payment-data?userId=${user?.attributes['userId'][0]}`
        )
      )
    );
  }

  getPagarmeClientByUserId() {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((user: any) =>
        this.http.get<IPagarmeCustomer>(
          `${environment.apiUrl}/pagarme/customers/user/${user?.attributes['userId'][0]}`
        )
      )
    );
  }

  getAdressByUserId() {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((user: any) =>
        this.http.get<{ data: IAddress[] }>(
          `${environment.apiUrl}/pagarme/customers/user/${user?.attributes['userId'][0]}/addresses?formatted=true`
        )
      )
    );
  }

  createPagarmeCustomer(body: IPagarmeCustomerRequestBody) {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((user: any) => {
        return this.http.post<IPagarmeCustomer>(
          `${environment.apiUrl}/pagarme/customers/user/${user?.attributes['userId'][0]}`,
          body
        );
      }),
      catchError((error) => throwError(() => error))
    );
  }

  createPagarmeCustomerAdress(body: IAddressRequestBody) {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((user: any) => {
        return this.http.post<{ data: IAddress }>(
          `${environment.apiUrl}/pagarme/customers/user/${user?.attributes['userId'][0]}/addresses`,
          body
        );
      }),
      catchError((error) => throwError(() => error))
    );
  }

  subscribePlan(data: {
    plan_id: number;
    type: 'recurrent' | 'year' | 'month';
    card_id?: string;
    payment_method: 'credit_card' | 'boleto' | 'pix';
    number_payments?: number;
    coupon_code?: string;
  }) {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((user: any) =>
        this.http.post<any>(`${environment.apiUrl}/pagarme/subscriptions`, {
          ...data,
          user_id: +user?.attributes['userId'][0],
        })
      ),
      catchError((error) => throwError(() => error))
    );
  }
}
