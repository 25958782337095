import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { UserService } from '../../services/user.service';
import { forkJoin } from 'rxjs';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { IFormation } from '../../../models/user';
import { LoaderComponent } from '../loader/loader.component';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-information',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgxMaskDirective,
    LoaderComponent,
  ],
  providers: [provideNgxMask()],
  templateUrl: './information.component.html',
  styleUrl: './information.component.scss',
})
export class InformationComponent implements OnInit {
  public isLoading: boolean = true;
  disableDocument: boolean = true;
  public formations: IFormation[] = [];
  public anotherFormationSelected: boolean = false;
  public avatar = '../../../assets/icons/user.svg';
  public profileFormControl!: FormGroup;

  constructor(
    private readonly userService: UserService,
    private readonly notificationsService: NotificationService,
    private readonly fb: FormBuilder
  ) {}

  ngOnInit(): void {
    forkJoin([
      this.userService.getUser(),
      this.userService.getUserPhoto(),
      this.userService.getFormations(),
    ]).subscribe({
      next: (data) => {
        const newBlob = new Blob([data[1]], { type: 'image/jpeg' });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onload = () => {
          this.avatar =
            newBlob.size > 1000 ? String(reader.result) : this.avatar;
        };

        this.formations = data[2].items;

        this.profileFormControl = this.fb.group({
          name: [data[0].name, [Validators.required]],
          email: [data[0].email, [Validators.required, Validators.email]],
          formation: [data[0].formation.id, [Validators.required]],
          anotherFormation: [''],
          pictureBase64: this.avatar,
          document: [data[0].cpf_cnpj, [Validators.required]],
        });

        this.profileFormControl.controls['formation'].valueChanges.subscribe(
          (value) => {
            const selectedFormation = this.formations.find(
              (formation) => formation.id === +value
            );

            if (selectedFormation?.name === 'OUTRA') {
              this.anotherFormationSelected = true;
              this.profileFormControl.controls['anotherFormation'].enable();
              this.profileFormControl.controls[
                'anotherFormation'
              ].setValidators([Validators.required]);
            } else {
              this.anotherFormationSelected = false;
              this.profileFormControl.controls['anotherFormation'].disable();
              this.profileFormControl.controls[
                'anotherFormation'
              ].clearValidators();
            }
          }
        );

        this.disableDocument = !!data[0].cpf_cnpj;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  public onPictureChange(event: any) {
    const file = event.target.files[0];
    const fileType = file?.type.split('/').pop();
    if (fileType && !['png', 'jpeg', 'jpg'].includes(fileType)) return;
    if (!file) return;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (!reader.result) return;

      this.profileFormControl.controls['pictureBase64'].setValue(
        reader.result.toString().split(',').pop()
      );
      this.avatar = reader.result as string;
    };
  }

  onFormationChange(event: any) {
    const selectedFormation = this.formations.find(
      (formation) => formation.id === +event.target.value
    );
    this.anotherFormationSelected = selectedFormation?.name === 'OUTRA';

    if (this.anotherFormationSelected) {
      this.profileFormControl.setControl(
        'anotherFormation',
        new FormControl('', [Validators.required])
      );
    } else {
      this.profileFormControl.setControl(
        'anotherFormation',
        new FormControl('')
      );
    }
  }

  removePicture() {
    this.profileFormControl.get('pictureBase64')?.setValue('');
    this.avatar = '../../../assets/icons/user.svg';
  }

  isValidInput(controlName: string): boolean {
    const control = this.profileFormControl.get(controlName);
    return !!control?.valid;
  }

  submitForm() {
    this.isLoading = true;
    const userBody: {
      email: string;
      name: string;
      formation_id: number;
      custom_formation: string | null;
    } = {
      name: this.profileFormControl.get('name')?.value,
      email: this.profileFormControl.get('email')?.value,
      formation_id: this.profileFormControl.get('formation')?.value,
      custom_formation: this.anotherFormationSelected
        ? this.profileFormControl.get('anotherFormation')?.value
        : null,
    };

    forkJoin([
      this.userService.updateUserInformations(userBody),
      this.userService.updateProfilePicture(
        this.profileFormControl.get('pictureBase64')?.value
      ),
    ]).subscribe({
      next: (data) => {
        if (data[0].id && data[1].success) {
          this.notificationsService.sendData({
            type: 'success',
            message: 'Informações alteradas com sucesso',
            visible: true,
          });
        }
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }
}
