import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { formatCurrency } from '../../../utils/formatters';
import { CommonModule } from '@angular/common';
import { CreditCardSubscriptionFormComponent } from './credit-card-subscription-form/credit-card-subscription-form.component';
import { BoletoSubscriptionFormComponent } from './boleto-subscription-form/boleto-subscription-form.component';
import { PixSubscriptionFormComponent } from './pix-subscription-form/pix-subscription-form.component';
import { PlanService } from '../../services/plan.service';
import { ICoupon, IPlan } from '../../../models/plan';
import {
  CheckboxRequiredValidator,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CreditCardSubscriptionFormComponent,
    BoletoSubscriptionFormComponent,
    PixSubscriptionFormComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss',
})
export class CheckoutComponent implements OnInit {
  public id: number;
  public period: 'yearly' | 'monthly';
  public currentTab: 'credit_card' | 'pix' | 'boleto' = 'credit_card';
  public plan: IPlan | null = null;
  public planPrice: number = 0;
  public totalToPay: number = 0;
  public couponCode: string = '';
  public coupon: ICoupon | null = null;
  public couponDiscount: number = 0;
  public totalWithDiscount: number = 0;
  protected maxNumberPayments: number = 2;

  public checkoutForm = new FormGroup({
    recurrent: new FormControl(false),
    number_payments: new FormControl(1, [
      Validators.required,
      Validators.min(1),
      Validators.max(this.maxNumberPayments),
    ]),
    coupon_code: new FormControl(''),
  });

  constructor(
    private readonly planService: PlanService,
    private readonly activatedRoute: ActivatedRoute,
    private route: ActivatedRoute
  ) {
    this.id = Number(this.activatedRoute.snapshot.paramMap.get('id'));

    this.period =
      String(this.activatedRoute.snapshot.paramMap.get('period')) === 'yearly'
        ? 'yearly'
        : 'monthly';

    this.maxNumberPayments = this.period === 'monthly' ? 24 : 2;

    this.checkoutForm.patchValue({
      number_payments: 1,
    });
  }

  ngOnInit(): void {
    this.checkoutForm.valueChanges.subscribe((res) => {
       if (res.recurrent) {
        this.currentTab = 'credit_card';
        this.planPrice =
          this.period === 'monthly'
            ? this.plan!.recurrency_price
            : this.plan!.yearly_price;
        this.totalToPay =
          this.period === 'monthly'
            ? this.plan!.recurrency_price
            : this.plan!.yearly_price;
         return;
       }

      if (this.period === 'monthly') {
        this.planPrice = this.plan!.monthly_price;
        this.totalToPay =
          res.number_payments === 1
            ? this.plan!.monthly_price
            : this.plan!.monthly_price * res.number_payments!;
      } else {
        this.totalToPay = this.plan!.yearly_price * res.number_payments!;
      }
      if (this.coupon != null) {
        if (this.coupon.coupon.type == 2) {
          this.couponDiscount =
            (this.coupon.coupon.value / 100) * this.totalToPay;
          this.totalWithDiscount = this.totalToPay - this.couponDiscount;
        } else {
          this.couponDiscount = this.coupon.coupon.value;
          this.totalWithDiscount = this.totalToPay - this.couponDiscount;
        }
      } else {
        this.couponDiscount = 0;
        this.totalWithDiscount = 0;
      }
    });

    this.planService.getPlanById(this.id).subscribe({
      next: (res) => {
        this.plan = res;
        this.planPrice =
          this.period === 'monthly' ? res.monthly_price : res.yearly_price;
        this.totalToPay = this.planPrice;
      },
    });

    this.route.queryParams.subscribe(params => {
      this.couponCode = params['cupom'] || ''; //parametro  de consulta para  o cupom


    });

  }

  setCoupon() {
    this.planService.getCoupon(this.couponCode).subscribe({
      next: (res) => {
        if (res) {
          if (res.status === 'Disponível') {
            this.checkoutForm.patchValue({
              coupon_code: this.couponCode,
            });
            this.coupon = res;
            if (res.coupon.type == 2) {
              this.couponDiscount = (res.coupon.value / 100) * this.totalToPay;
              this.totalWithDiscount = this.totalToPay - this.couponDiscount;
            } else {
              this.couponDiscount = res.coupon.value;
              this.totalWithDiscount = this.totalToPay - this.couponDiscount;
            }
          } else if (res.status === 'Esgotado') {
            void Swal.fire({
              title: 'Cupom esgotado',
              text: 'Tente novamente com um cupom disponível.',
              confirmButtonText: 'OK',
            });
            this.coupon = null;
            this.couponDiscount = 0;
            this.totalWithDiscount = 0;
          } else {
            void Swal.fire({
              title: 'Cupom não encontrado',
              text: 'Tente novamente com um cupom válido.',
              confirmButtonText: 'OK',
            });
            this.coupon = null;
            this.couponDiscount = 0;
            this.totalWithDiscount = 0;
          }
        }
      },
    });
  }

  get planPriceFormatted() {
    return formatCurrency(this.planPrice);
  }

  get recurrent() {
    return this.checkoutForm.get('recurrent')?.value;
  }

  get totalToPayFormatted() {
    return formatCurrency(this.totalToPay);
  }

  get couponDiscountFormatted() {
    return formatCurrency(this.couponDiscount);
  }

  get totalWithDiscountFormatted() {
    return formatCurrency(this.totalWithDiscount);
  }

  activeTab(tab: typeof this.currentTab) {
    if (this.recurrent) return;
    this.currentTab = tab;
  }
}
