import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  userId = false;

  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private readonly userService: UserService
  ) {
    super(router, keycloak);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async canMatch(route: Route, segments: UrlSegment[]): Promise<boolean> {
    try {
      this.authenticated = this.keycloakAngular.isLoggedIn();

      if (this.authenticated)
        this.checkUserId(
          this.router.getCurrentNavigation()?.extractedUrl.toString()
        );

      const result = await this.checkAccessAllowed(
        route.data,

        window.location.host +
          this.router.getCurrentNavigation()?.extractedUrl.toString()
      );
      return result;
    } catch (error) {
      throw new Error(
        `An error happened during access validation. Details: ${error}`
      );
    }
  }

  async checkAccessAllowed(data: any, redirect?: string): Promise<boolean> {
    if (!this.authenticated) {
      try {
        if (redirect?.includes('localhost')) {
          redirect = 'http://' + redirect;
        } else {
          redirect = 'https://' + redirect;
        }
        await this.keycloakAngular.login({ redirectUri: redirect });
        return false;
      } catch (error) {
        return false;
      }
    }

    const requiredRoles = data as string[];

    if (!requiredRoles || requiredRoles.length === 0) {
      return true;
    } else if (!this.roles || this.roles.length === 0) {
      return false;
    } else {
      return requiredRoles.some((requiredRole) =>
        this.roles.includes(requiredRole)
      );
    }
  }

  checkUserId(route: any) {
    return this.userService.getUserAccount().subscribe({
      next: (user) => {
        const userId = user?.attributes?.['userId'];

        if (!userId && !route.includes(`/after-signup`)) {
          window.location.assign(`/after-signup`);
          return false;
        }
        return true;
      },
      error: () => {
        this.userId = false;
      },
    });
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }
    return true;
  }
}
