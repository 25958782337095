import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { catchError, from, switchMap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { IFormation, INotification, IUser } from '../../models/user';
import { IUserPlan } from '../../models/plan';
import { ICard } from '../../models/card';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private readonly http: HttpClient,
    private readonly keycloakService: KeycloakService
  ) {}

  getUser() {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((user: any) => {
        return this.http.get<IUser>(
          `${environment.apiUrl}/users/${user?.attributes['userId'][0]}?include=formation`
        );
      })
    );
  }

  getUserPhoto() {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((user: any) => {
        return this.http.get(
          `${environment.apiUrl}/users/${user?.attributes['userId'][0]}/photo`,
          { responseType: 'blob' }
        );
      })
    );
  }

  getUserPlan() {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((user: any) => {
        return this.http.get<IUserPlan>(
          `${environment.apiUrl}/userplans/user/${user?.attributes['userId'][0]}`
        );
      })
    );
  }

  storageUsed() {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((user: any) => {
        return this.http.get<{ total: number }>(
          `${environment.apiUrl}/documents/user/${user?.attributes['userId'][0]}/total`
        );
      })
    );
  }

  farmsCreated() {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((user: any) => {
        return this.http.get<{ total: number }>(
          `${environment.apiUrl}/farms/user/${user?.attributes['userId'][0]}/total`
        );
      })
    );
  }

  getFormations() {
    return this.http.get<{ items: IFormation[] }>(
      `${environment.apiUrl}/formations`
    );
  }

  getCards() {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((user: any) => {
        return this.http.get<{ data: ICard[] }>(
          `${environment.apiUrl}/pagarme/customers/user/${user?.attributes['userId'][0]}/cards`
        );
      })
    );
  }

  getNotifications() {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((profile: any) => {
        return this.http.get<INotification[]>(
          `${environment.apiUrl}/simple-alerts/${profile.attributes.userId[0]}/user`
        );
      })
    );
  }

  getUserAccount() {
    return this.http.get<any>(`${environment.auth_url_realms}/account`);
  }

  updateUserInformations(body: {
    email: string;
    name: string;
    formation_id: number;
    custom_formation: string | null;
  }) {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((user: any) => {
        return this.http.patch<IUser>(
          `${environment.apiUrl}/users/${user?.attributes['userId'][0]}`,
          body
        );
      })
    );
  }

  updateProfilePicture(pictureBase64: string) {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((user: any) => {
        return this.http.patch<{ success: boolean }>(
          `${environment.apiUrl}/users/${user?.attributes['userId'][0]}/photo`,
          { photo_base64: pictureBase64 }
        );
      })
    );
  }

  deleteCardById(cardId: string) {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((user: any) => {
        return this.http.delete<{ success: boolean }>(
          `${environment.apiUrl}/pagarme/customers/user/${user?.attributes['userId'][0]}/cards/${cardId}`
        );
      })
    );
  }

  userHasPagarmeClient() {
    return from(this.getUser()).pipe(
      switchMap((user) => {
        return from([!!user.external_id]);
      })
    );
  }

  CreateUserAfterSignUp(body: { email: string; name: string; phone: string }) {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((user: any) => {
        return this.http
          .post(`${environment.apiUrl}/users`, {
            ...body,
            keycloak_user_id: user?.id,
          })
          .pipe(
            switchMap((resPostUsers: any) => {
              return this.getUserAccount().pipe(
                switchMap((resGetAccount: any) => {
                  resGetAccount.attributes.userId = resPostUsers.id;

                  return this.createUserAccount(resGetAccount);
                }),
                catchError((err) => throwError(() => new Error(err)))
              );
            }),
            catchError((err) => throwError(() => new Error(err)))
          );
      }),
      catchError((err) => throwError(() => new Error(err)))
    );
  }

  createUserAccount(body: any) {
    return this.http.post(`${environment.auth_url_realms}/account`, {
      ...body,
    });
  }

  logout(redirect: string) {
    return this.keycloakService.logout(redirect);
  }
}
