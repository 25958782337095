<div *ngIf="isLoading" class="w-full h-svh flex items-center justify-center">
  <app-loader></app-loader>
</div>
<div
  *ngIf="!isLoading"
  class="flex flex-col mx-auto py-4 w-96 max-w-full px-4 lg:absolute top-1/2 left-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2"
>
  <img
    (click)="goBack()"
    class="w-8 h-8 mb-8 cursor-pointer"
    src="../../../../../assets/icons/arrow-left.svg"
    alt=""
  />

  <h1 class="text-4xl font-medium text-white text-center">Adicionar Cartão</h1>
  <span class="text-sm text-gray-200 mt-2 mb-8 text-center">
    Caso você tenha utilizado um cartão no momento da assinatura do plano, ele
    não será alterado.
  </span>

  <app-card-informations-form action="add"></app-card-informations-form>
</div>
