import { Component } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
})
export class ToastComponent {
  public type: 'success' | 'error' | 'warning' | 'info' = 'error';
  public message!: string;
  public visible: boolean = false;

  constructor(private readonly notificationService: NotificationService) {
    this.notificationService.data.subscribe({
      next: (n) => {
        if (n?.visible) {
          this.visible = true;
          this.message = n.message;
          this.type = n.type;
        }
      },
    });
  }

  hidden() {
    this.visible = false;
  }
}
