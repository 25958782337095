import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { environment } from '../../../environments/environment';
import { UserService } from '../../services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [RouterOutlet, CommonModule, RouterLink, RouterLinkActive],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
})
export class MainComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public todayDate = new Date().toLocaleDateString('pt-BR');
  public userData: { name?: string; photoUrl?: string } = {
    photoUrl: '../../../../../assets/icons/user.svg',
  };
  env = environment;

  constructor(private readonly userService: UserService) {}
  ngOnInit(): void {
    this.getUserInfos();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  getUserInfos() {
    const user = this.userService.getUser().subscribe({
      next: (data) => {
        this.userData.name = data.name;
      },
    });

    const photo = this.userService.getUserPhoto().subscribe({
      next: (data) => {
        const newBlob = new Blob([data], { type: 'image/jpeg' });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onload = () => {
          this.userData.photoUrl =
            newBlob.size > 1000
              ? String(reader.result)
              : this.userData.photoUrl;
        };
        //const url = URL.createObjectURL(data);
        //this.userData.photoUrl = url;
      },
    });

    this.subscriptions.push(user, photo);
  }
  async submit(e: any) {
    e.preventDefault();
    // window.sessionStorage.clear();

    await this.userService.logout(environment.basePortal);
  }

  isSidebarOpen = true;
  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }
}
