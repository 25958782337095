import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { from, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';
import { ICard, ICardBodyRequest } from '../../models/card';

@Injectable({
  providedIn: 'root',
})
export class CardService {
  constructor(
    private readonly keycloakService: KeycloakService,
    private readonly http: HttpClient
  ) {}

  addNewCard(body: ICardBodyRequest) {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((user: any) => {
        return this.http.post<ICard>(
          `${environment.apiUrl}/pagarme/customers/user/${user?.attributes['userId'][0]}/cards`,
          body
        );
      })
    );
  }

  changeMainCard(cardInformations: ICardBodyRequest) {
    return from(this.keycloakService.loadUserProfile()).pipe(
      switchMap((user: any) => {
        return this.http.patch<{ success: boolean }>(
          `${environment.apiUrl}/pagarme/subscriptions/card`,
          {
            user_id: +user?.attributes['userId'][0],
            card: cardInformations,
          }
        );
      })
    );
  }
}
