/* eslint-disable no-debugger */
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { PlanService } from '../../../services/plan.service';
import { ILastTransaction } from '../../../../models/plan';
import { RouterLink } from '@angular/router';
import { NgxMaskDirective } from 'ngx-mask';
import { getDayMonthYear } from '../../../../utils/formatters';
import { UserService } from '../../../services/user.service';
import { isValidCPF } from '../../../../utils/validators';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-boleto-subscription-form',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, RouterLink, NgxMaskDirective],
  templateUrl: './boleto-subscription-form.component.html',
  styleUrl: './boleto-subscription-form.component.scss',
})
export class BoletoSubscriptionFormComponent {
  @Input({ required: true }) public plan_id!: number;
  @Input({ required: true }) public values!: any;
  public isLoading: boolean = false;
  public boletoData: ILastTransaction | null = null;
  private userEmail: string = '';

  public pagarmeClientForm = new FormGroup({
    name: new FormControl('', {
      validators: [Validators.required],
    }),
    // gender: new FormControl('', {
    //   validators: [Validators.required],
    // }),
    // birth_day: new FormControl('', {
    //   validators: [Validators.required],
    // }),
    // birth_month: new FormControl('', {
    //   validators: [Validators.required],
    // }),
    // birth_year: new FormControl('', {
    //   validators: [Validators.required],
    // }),
    phone: new FormControl('', {
      validators: [Validators.required],
    }),
    document: new FormControl('', {
      validators: [Validators.required],
    }),
  });

  constructor(
    private readonly plansService: PlanService,
    private readonly userService: UserService
  ) {
    this.userService.getUser().subscribe({
      next: (data) => (this.userEmail = data.email),
    });
  }

  ngOnInit(): void {
    this.plansService.getPagarmeClientByUserId().subscribe({
      next: (data) => {
        if (data) {
          const {
            name,
            // gender,
            phones: { mobile_phone },
            // birthdate,
            document,
          } = data;

          // const formatedBirthdate = getDayMonthYear(birthdate);
          this.pagarmeClientForm.patchValue({
            // birth_day: formatedBirthdate.day,
            // birth_month: formatedBirthdate.month,
            // birth_year: formatedBirthdate.year,
            name,
            // gender,
            phone: `${mobile_phone.area_code}${mobile_phone.number}`,
            document,
          });
        }
      },
    });
  }

  submit() {
    this.isLoading = true;

    // Cria/atualiza Cliente antes do boleto
    this.plansService
      .createPagarmeCustomer({
        document: this.pagarmeClientForm.get('document')!.value!,
        type: isValidCPF(this.pagarmeClientForm.get('document')!.value!)
          ? 'individual'
          : 'company',
        document_type: isValidCPF(
          this.pagarmeClientForm.get('document')!.value!
        )
          ? 'CPF'
          : 'CNPJ',
        email: this.userEmail,
        name: this.pagarmeClientForm.get('name')!.value!,
        // gender: this.pagarmeClientForm.get('gender')!.value! as
        //   | 'male'
        //   | 'female',
        // birth_date: `${this.pagarmeClientForm.get('birth_year')!
        //   .value!}-${this.pagarmeClientForm.get('birth_month')!
        //   .value!}-${this.pagarmeClientForm.get('birth_day')!.value!}`,
        mobile_phone: `+55${this.pagarmeClientForm.get('phone')!.value!}`,
      })
      .pipe(
        switchMap((res) => {
          // debugger;
          // console.log(res);
          // Chamar o serviço para gerar um novo boleto
          return this.plansService.subscribePlan({
            payment_method: 'boleto',
            plan_id: this.plan_id,
            type: 'year',
            number_payments: this.values.number_payments,
            coupon_code: this.values.coupon_code ?? null,
          });
        })
      )
      .subscribe({
        next: (data) => {
          // debugger;
          // console.log(data);
          this.boletoData = data.charges[0]
            .last_transaction as ILastTransaction;
          this.boletoData.due_at = new Date(
            this.boletoData.due_at
          ).toLocaleDateString('pt-BR');
          this.isLoading = false;
        },
      });
  }

  copyToClipboard(text: string) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Texto copiado para a área de transferência:', text);
      })
      .catch((err) => {
        console.error('Erro ao copiar texto:', err);
      });
  }
}
