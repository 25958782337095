<div class="flex flex-col">
  <span class="text-error text-xs mt-4">* Campos obrigatórios</span>
  <details class="collapse bg-base-200">
    <summary class="collapse-title text-xl font-medium">
      Informação de cadastro
    </summary>
    <div class="collapse-content">
      <form
        [formGroup]="pagarmeClientForm"
        class="grid grid-cols-4 gap-4 items-end"
      >
        <div class="form-control col-span-full">
          <label class="label">
            <span class="label-text">Nome completo</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            formControlName="name"
            placeholder="Nome completo"
          />
        </div>

        <!-- <div class="form-control col-span-full">
          <label class="label">
            <span class="label-text">Gênero</span>
          </label>
          <select formControlName="gender" class="select select-bordered">
            <option value="" selected disabled hidden>Selecione</option>
            <option value="male">Masculino</option>
            <option value="female">Feminino</option>
          </select>
        </div>

        <div class="form-control col-span-1">
          <label class="label">
            <span class="label-text">Dia de nascimento</span>
          </label>
          <input
            type="text"
            mask="00"
            class="input input-bordered"
            formControlName="birth_day"
          />
        </div> -->

        <!-- <div class="form-control col-span-1">
          <label class="label">
            <span class="label-text">Mes de nascimento</span>
          </label>
          <input
            type="text"
            mask="00"
            class="input input-bordered"
            formControlName="birth_month"
          />
        </div>

        <div class="form-control col-span-2">
          <label class="label">
            <span class="label-text">Ano de nascimento</span>
          </label>
          <input
            type="text"
            mask="0000"
            class="input input-bordered"
            formControlName="birth_year"
          />
        </div> -->

        <div class="form-control col-span-full">
          <label class="label">
            <span class="label-text">Celular</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            formControlName="phone"
            mask="(00) 00000-0000"
          />
        </div>

        <div class="form-control col-span-full">
          <label class="label">
            <span class="label-text">CPF/CNPJ</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            formControlName="document"
            mask="CPF_CNPJ"
          />
        </div>
      </form>
    </div>
  </details>

  <button
    (click)="submit()"
    class="btn btn-primary my-4"
    [disabled]="pagarmeClientForm.invalid || isLoading"
  >
    {{ isLoading ? "Gerando pix" : "Gerar pix" }}
  </button>

  <div class="flex flex-col items-center mt-8" *ngIf="pixData">
    <h2 class="font-bold text-2xl">Informações do pix</h2>
    <span class="my-2">Vencimento: {{ pixData.due_at.toLocaleString() }}</span>

    <p>Código Pix:</p>

    <span
      class="flex flex-col items-center text-wrap w-full md:w-1/2 border-2 border-gray-300 overflow-hidden text-ellipsis whitespace-nowrap p-2 break-all"
    >
      {{ pixData.qr_code }}
    </span>

    <button
      class="btn btn-primary btn-sm my-2"
      type="button"
      (click)="copyToClipboard(pixData.qr_code)"
    >
      Copiar código Pix
    </button>

    <!-- <p class="mt-4">Código de barras:</p>
    <img class="w-full md:w-1/2" src="{{ pixData.qr_code }}" alt="" /> -->

    <p class="mt-4">Se desejar, pague tambem por QR Code</p>
    <img
      class="border-2 border-gray-300 md:w-1/2 max-w-1x1"
      src="{{ pixData.qr_code_url }}"
      alt=""
    />

    <a class="mt-8 text-primary" [routerLink]="['/']">
      Retornar a página inicial</a
    >
  </div>
</div>
