<div class="flex flex-col">
  <h2 class="text-4xl font-medium text-white">Cartões</h2>
  <div class="flex gap-x-4 my-8">
    <a
      *ngIf="userHasRecurrentPlan"
      href="/cobranca/mudar-cartao-principal"
      class="btn btn-outline"
    >
      Trocar cartão principal
    </a>

    <a href="/cobranca/adicionar-cartao" class="btn btn-success"
      >Adicionar novo cartão</a
    >
  </div>

  <div class="flex flex-wrap gap-4">
    <h3 class="font-medium text-white mt-8" *ngIf="!cards.length && !isLoading">
      Nenhum cartão encontrado...
    </h3>

    <app-loader *ngIf="isLoading" class="mt-8"></app-loader>

    <div class="flex flex-col w-full md:w-96" *ngFor="let card of cards">
      <div
        class="cursor-default bg-green-400 shadow-lg shadow-gray-900 p-5 rounded-xl my-3 w-full md:w-96 h-52 max-w-full flex flex-col content-between"
      >
        <span
          class="uppercase text-15/22 font-bold text-gray-200 text-right italic"
        >
          {{ card.brand }}
        </span>
        <div class="flex mb-6 mt-10">
          <div class="grow">
            <small class="text-12/16 text-gray-200"> Número do cartão </small>
            <p class="text-15/22 font-bold text-gray-200">
              {{
                formatCardNumbers(card.first_six_digits, card.last_four_digits)
              }}
            </p>
          </div>
          <div class="">
            <small class="text-12/16 text-gray-200"> Validade </small>
            <p class="text-15/22 font-bold text-gray-200">
              {{ card.exp_month }}/{{ card.exp_year }}
            </p>
          </div>
        </div>
        <span class="uppercase text-sm font-bold text-gray-200 text-left">
          {{ card.holder_name }}
        </span>
      </div>

      <button
        [ngClass]="{ 'btn-disabled': card.is_main }"
        (click)="deleteCard(card.id)"
        class="btn btn-sm btn-error"
      >
        Excuir cartão
      </button>
    </div>
  </div>
</div>
