<div class="flex flex-col">
  <h2 class="text-4xl font-medium text-white">Informações</h2>
  <app-loader *ngIf="isLoading" class="mt-8"></app-loader>
  <form
    *ngIf="!isLoading"
    class="mt-8 w-full lg:w-1/2"
    [formGroup]="profileFormControl"
  >
    <div className="form-control">
      <div class="flex items-center space-x-6">
        <img
          id="preview_img"
          class="h-16 w-16 object-cover rounded-full"
          src="{{ avatar }}"
          alt="Current profile photo"
        />
        <div class="flex flex-col items-start justify-center space-y-4">
          <label for="profilePicture" class="btn btn-success btn-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 mr-2 fill-white inline"
              viewBox="0 0 32 32"
            >
              <path
                d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                data-original="#000000"
              />
              <path
                d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                data-original="#000000"
              />
            </svg>
            Escolher foto
            <input
              type="file"
              id="profilePicture"
              class="hidden"
              (change)="onPictureChange($event)"
            />

            <input type="text" class="hidden" formControlName="pictureBase64" />
          </label>
          <button
            type="button"
            (click)="removePicture()"
            [disabled]="!profileFormControl.get('pictureBase64')?.value"
            class="btn btn-error btn-sm"
          >
            Remover foto
          </button>
        </div>
      </div>
    </div>

    <div class="form-control">
      <label class="label">
        <span class="label-text text-white">Nome</span>
      </label>
      <input type="text" class="input input-bordered" formControlName="name" />
      <div class="label">
        <span
          class="label-text-alt text-error"
          *ngIf="!isValidInput('name') || isLoading"
        >
          Nome obrigatório
        </span>
      </div>
    </div>
    <div class="form-control">
      <label class="label">
        <span class="label-text text-white">Email</span>
      </label>
      <input
        type="email"
        class="input input-bordered"
        formControlName="email"
      />
      <div class="label">
        <span class="label-text-alt text-error" *ngIf="!isValidInput('email')"
          >email inválido</span
        >
      </div>
    </div>
    <div class="form-control">
      <label class="label">
        <span class="label-text text-white">Formação</span>
      </label>
      <select
        formControlName="formation"
        class="select select-bordered text-black"
      >
        <option *ngFor="let formation of formations" value="{{ formation.id }}">
          {{ formation.name }}
        </option>
      </select>
      <div class="label">
        <span
          class="label-text-alt text-error"
          *ngIf="!isValidInput('formation')"
        >
          Formação obrigatória
        </span>
      </div>
    </div>
    <div *ngIf="anotherFormationSelected" class="form-control">
      <label class="label">
        <span class="label-text text-white">Outra formação</span>
      </label>
      <input
        type="text"
        class="input input-bordered"
        formControlName="anotherFormation"
      />
      <div class="label">
        <span
          class="label-text-alt text-error"
          *ngIf="!isValidInput('anotherFormation')"
        >
          Campo obrigatório
        </span>
      </div>
    </div>
    <div class="form-control">
      <label class="label">
        <span class="label-text text-white">CPF/CNPJ</span>
      </label>
      <input
        [readOnly]="disableDocument"
        type="text"
        class="input input-bordered"
        [ngClass]="{ 'input-disabled': disableDocument }"
        formControlName="document"
        mask="CPF_CNPJ"
      />
      <div class="label">
        <span
          class="label-text-alt text-error"
          *ngIf="!isValidInput('document')"
        >
          Documento obrigatório
        </span>
      </div>
    </div>
    <button
      (click)="submitForm()"
      class="btn btn-success btn-sm mt-4"
      [disabled]="isLoading || !profileFormControl.valid"
    >
      Salvar
    </button>
  </form>
</div>
