/* eslint-disable no-debugger */
import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { PlanService } from '../../../services/plan.service';
import { getDayMonthYear } from '../../../../utils/formatters';
import { CommonModule } from '@angular/common';
import { forkJoin, mergeMap, switchMap } from 'rxjs';
import { NgxMaskDirective } from 'ngx-mask';
import { UserService } from '../../../services/user.service';
import { isValidCPF } from '../../../../utils/validators';
import { CardService } from '../../../services/card.service';
import { NotificationService } from '../../../services/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-credit-card-subscription-form',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, NgxMaskDirective],
  templateUrl: './credit-card-subscription-form.component.html',
  styleUrl: './credit-card-subscription-form.component.scss',
})
export class CreditCardSubscriptionFormComponent implements OnInit {
  @Input({ required: true }) public planId!: number;
  @Input({ required: true }) public period!: 'yearly' | 'monthly';
  @Input({ required: true }) public values!: any;
  public isLoading: boolean = false;
  private userEmail: string = '';

  public pagarmeClientForm = new FormGroup({
    name: new FormControl('', {
      validators: [Validators.required],
    }),
    // gender: new FormControl('', {
    //   validators: [Validators.required],
    // }),
    // birth_day: new FormControl('', {
    //   validators: [Validators.required],
    // }),
    // birth_month: new FormControl('', {
    //   validators: [Validators.required],
    // }),
    // birth_year: new FormControl('', {
    //   validators: [Validators.required],
    // }),
    phone: new FormControl('', {
      validators: [Validators.required],
    }),
    document: new FormControl('', {
      validators: [Validators.required],
    }),
  });

  public pagarmeAdressForm = new FormGroup({
    cep: new FormControl('', {
      validators: [Validators.required],
    }),
    state: new FormControl('', {
      validators: [Validators.required],
    }),
    city: new FormControl('', {
      validators: [Validators.required],
    }),
    neighborhood: new FormControl('', {
      validators: [Validators.required],
    }),
    street: new FormControl('', {
      validators: [Validators.required],
    }),
    number: new FormControl('', {
      validators: [Validators.required],
    }),
    complement: new FormControl(''),
  });

  public creditCardForm = new FormGroup({
    number: new FormControl('', {
      validators: [Validators.required],
    }),
    holder_name: new FormControl('', {
      validators: [Validators.required],
    }),
    holder_document: new FormControl('', {
      validators: [Validators.required],
    }),
    expiration_date: new FormControl('', {
      validators: [Validators.required],
    }),
    cvv: new FormControl('', {
      validators: [Validators.required],
    }),
  });

  public BRAZILIAN_STATES = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];

  constructor(
    private readonly plansService: PlanService,
    private readonly userService: UserService,
    private readonly cardService: CardService,
    private readonly notificationService: NotificationService,
    private readonly router: Router
  ) {
    this.userService.getUser().subscribe({
      next: (data) => (this.userEmail = data.email),
    });
  }

  ngOnInit(): void {
    forkJoin([
      this.plansService.getPagarmeClientByUserId(),
      this.plansService.getAdressByUserId(),
    ]).subscribe({
      next: (data) => {
        // debugger;
        if (data[0]) {
          const {
            name,
            // gender,
            phones: { mobile_phone },
            // birthdate,
            document,
          } = data[0];
          // const formatedBirthdate = getDayMonthYear(birthdate);
          this.pagarmeClientForm.patchValue({
            // birth_day: formatedBirthdate.day,
            // birth_month: formatedBirthdate.month,
            // birth_year: formatedBirthdate.year,
            name,
            // gender,
            phone: `${mobile_phone.area_code}${mobile_phone.number}`,
            document,
          });
        }
        if (data[1] && data[1].data && data[1].data.length > 0) {
          const {
            data: [{ city, state, street, number, district, zip_code }],
          } = data[1];
          this.pagarmeAdressForm.patchValue({
            cep: zip_code,
            city,
            state,
            neighborhood: district,
            street,
            number,
          });
        }
      },
    });
  }

  submit() {
    this.isLoading = true;
    // Cria/atualiza Cliente antes do pagamento
    this.plansService
      .createPagarmeCustomer({
        document: this.pagarmeClientForm.get('document')!.value!,
        type: isValidCPF(this.pagarmeClientForm.get('document')!.value!)
          ? 'individual'
          : 'company',
        document_type: isValidCPF(
          this.pagarmeClientForm.get('document')!.value!
        )
          ? 'CPF'
          : 'CNPJ',
        email: this.userEmail,
        name: this.pagarmeClientForm.get('name')!.value!,
        // gender: this.pagarmeClientForm.get('gender')!.value! as
        //   | 'male'
        //   | 'female',
        // birth_date: `${this.pagarmeClientForm.get('birth_year')!
        //   .value!}-${this.pagarmeClientForm.get('birth_month')!
        //   .value!}-${this.pagarmeClientForm.get('birth_day')!.value!}`,
        mobile_phone: `+55${this.pagarmeClientForm.get('phone')!.value!}`,
      })
      .pipe(
        switchMap((res) => {
          // debugger;
          // console.log(res);
          // Cria/atualiza Endereço de Cliente antes do pagamento
          return this.plansService.createPagarmeCustomerAdress({
            city: this.pagarmeAdressForm.get('city')!.value!,
            complement: this.pagarmeAdressForm.get('complement')!.value!,
            district: this.pagarmeAdressForm.get('neighborhood')!.value!,
            number: this.pagarmeAdressForm.get('number')!.value!,
            state: this.pagarmeAdressForm.get('state')!.value!,
            street: this.pagarmeAdressForm.get('street')!.value!,
            zip_code: this.pagarmeAdressForm.get('cep')!.value!,
          });
        })
      )
      .pipe(
        switchMap((res) => {
          // debugger;
          // console.log(res);
          // Chamar o serviço para gerar um novo cartão de crédito
          return this.cardService.addNewCard({
            cvv: this.creditCardForm.get('cvv')!.value!,
            exp_month: +this.creditCardForm
              .get('expiration_date')!
              .value!.substring(0, 2),
            exp_year: +this.creditCardForm
              .get('expiration_date')!
              .value!.substring(2, 4),
            holder_document: this.creditCardForm.get('holder_document')!.value!,
            holder_name: this.creditCardForm.get('holder_name')!.value!,
            number: this.creditCardForm.get('number')!.value!,
          });
        }),
        mergeMap((res) => {
          // debugger;
          // console.log(res);
          // Chamar o serviço para gerar uma nova assinatura
          return this.plansService.subscribePlan({
            plan_id: this.planId,
            type: this.values.recurrent
              ? 'recurrent'
              : this.period === 'yearly'
              ? 'year'
              : 'month',
            card_id: res.id,
            payment_method: 'credit_card',
            number_payments: !this.values.recurrent
              ? this.values.number_payments
              : null,
            coupon_code: this.values.coupon_code ?? null,
          });
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.sendData({
            message: 'Plano assinado com sucesso!',
            type: 'success',
            visible: true,
          });

          this.isLoading = false;
          setTimeout(() => {
            this.router.navigate(['/']);
          }, 3000);
        },
        error: (err) => {
          this.isLoading = false;
          this.notificationService.sendData({
            message: `Erro ao assinar plano: ${err}`,
            type: 'error',
            visible: true,
          });
        },
      });
  }
}
