<form
  [formGroup]="cardInformationsForm"
  (ngSubmit)="submitForm()"
  class="bg-gray-600 shadow-lg shadow-gray-600 p-4 rounded-lg"
>
  <div class="mb-5">
    <label class="block mb-2 text-sm text-white">
      Nome impresso no cartão
    </label>
    <input
      formControlName="holder_name"
      type="text"
      class="input w-full input-md"
      placeholder="Lucas Silva"
    />
  </div>

  <div class="mb-5">
    <label class="block mb-2 text-sm text-white"> Número do cartão </label>
    <input
      formControlName="number"
      type="text"
      class="input w-full input-md"
      placeholder="0000 0000 0000 0000"
      mask="0000 0000 0000 0000"
    />
    <span *ngIf="number?.errors?.['notValid']" class="text text-error">
      Cartão inválido
    </span>
  </div>

  <div class="mb-5">
    <label class="block mb-2 text-sm text-white"> CPF/CNPJ do titular </label>
    <input
      formControlName="holder_document"
      type="text"
      class="input w-full input-md"
      placeholder="000.000.000-00"
      mask="CPF_CNPJ"
    />
    <span *ngIf="holder_document?.errors?.['notValid']" class="text text-error">
      Documento inválido
    </span>
  </div>

  <div class="grid grid-cols-2 gap-x-4">
    <div class="mb-5 col-span-1">
      <label class="block mb-2 text-sm text-white"> Mês de expiração </label>
      <input
        formControlName="exp_month"
        type="text"
        class="input w-full input-md"
        placeholder="12"
        mask="00"
      />
    </div>

    <div class="mb-5 col-span-1">
      <label class="block mb-2 text-sm text-white"> Ano de expiração </label>
      <input
        formControlName="exp_year"
        type="text"
        class="input w-full input-md"
        placeholder="2099"
        mask="0000"
      />
    </div>
  </div>

  <div class="mb-5">
    <label class="block mb-2 text-sm text-white"> CVV </label>
    <input
      formControlName="cvv"
      type="text"
      class="input w-full input-md"
      placeholder="000"
      mask="000"
    />
    <span *ngIf="cvv?.errors?.['notValid']" class="text text-error">
      CVV inválido HAHA
    </span>
  </div>

  <button
    type="submit"
    class="btn btn-block btn-md btn-success"
    [disabled]="cardInformationsForm.invalid || isLoading"
  >
    {{
      isLoading
        ? "Aguarde..."
        : action === "add"
        ? "Adicionar cartão"
        : "Alterar cartão"
    }}
  </button>
</form>
