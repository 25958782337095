/**
 * Formats a given number as a currency string in the Brazilian Real (BRL) format.
 *
 * @param {number} value - The number to be formatted in cents. e.g. 100 = R$ 1.00
 * @return {string} The formatted currency string.
 */
export function formatCurrency(value: number) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value / 100);
}

/**
 * Formats the given card numbers into a formatted string.
 *
 * @param {string} first_six_digits - The first six digits of the card number.
 * @param {string} last_four_digits - The last four digits of the card number.
 * @return {string} The formatted card number string.
 */
export function formatCardNumbers(
  first_six_digits: string,
  last_four_digits: string
) {
  const fixedPart1 = '**';
  const fixedPart2 = '****';

  // Cria a string formatada
  const formattedString = `${first_six_digits.slice(
    0,
    4
  )} ${first_six_digits.slice(
    4,
    6
  )}${fixedPart1} ${fixedPart2} ${last_four_digits}`;

  return formattedString;
}

// "2023-10-27T00:33:20Z"

export function getDayMonthYear(isoDate: string) {
  const date = new Date(isoDate);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() é zero-indexado
  const year = String(date.getUTCFullYear());

  return { day, month, year };
}
