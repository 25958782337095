<div
  *ngIf="userPlanData"
  class="grid grid-cols-1 lg:grid-cols-3 lg:grid-rows-2 w-full gap-2"
>
  <div
    [ngClass]="{
      'bg-success': userPlanData.payment_status === 'paid',
      'bg-warning': userPlanData.payment_status === 'pending',
      'bg-error': !!userPlanData.payment_status,
     }"
    class="flex flex-col rounded-xl col-span-1 lg:row-span-2"
  >
    <div class="p-8 flex flex-col flex-1">
      <p class="text-2x text-white">
        Plano assinado ({{
          userPlanData.payment_status === "paid"
            ? "PAGO"
            : userPlanData.payment_status === "pending"
            ? "Pendente"
            : userPlanData.payment_status === "expired"
            ? "Seu  Plano Expirou"
            : "Entre em contato com o Suporte"
        }})
      </p>
      <div id="svgIconPlan" class="w-3/4"></div>
      <div class="mt-4" *ngIf="!userPlanData.plan">
        <p class="text-2xl font-bold">Nenhum plano assinado</p>
        <a
          href="{{ env.basePortal }}/Planos"
          class="btn btn-sm btn-success mt-4"
          >Assinar plano</a
        >
      </div>
    </div>

    <div
      *ngIf="userPlanData.plan"
      [ngClass]="
        userPlanData.payment_status === 'paid' ? 'bg-primary/20' : 'bg-black/50'
      "
      class="p-8 flex flex-col bg-primary/20 rounded-xl flex-1"
    >
      <p class="text-sm font-bold text-white">Informações da assinatura:</p>

      <h2 class="text-6xl font-medium mt-8">
        {{ diffInDays(userPlanData.expiration_date) }} dias
      </h2>
      <p class="text-sm text-white">para expirar sua assinatura!</p>
    </div>
  </div>

  <div
    class="grid grid-cols-1 gap-2 lg:grid-cols-2 lg:col-span-2 lg:grid-rows-2 lg:row-span-2"
  >
    <div
      *ngIf="userPlanData.plan"
      class="bg-dark rounded-xl p-4 flex flex-col justify-between col-span-1"
    >
      <p class="text-2xl text-white">Unidades produtivas criadas</p>

      <div class="flex justify-between items-center">
        <p class="font-medium text-lg text-white">
          <span>{{ userStorageData.ups.used }}</span>
          /
          <span class="text-xs">{{ userStorageData.ups.total }}</span>
        </p>

        <div class="w-full relative">
          <canvas width="200" height="200" id="upChart"> </canvas>
        </div>
      </div>
    </div>


    <div
      [ngClass]="
        'bg-dark'
      "
      class="relative rounded-xl p-4 flex flex-col justify-between"
    >
      <h6 class="text-2xl text-white">Última transação</h6>
      <p class="text-4xl font-bold text-white">
        {{ formatCurrency(paymentoInformation.amount) }}
      </p>
      <p class = "text-4x1 font-bold text-white">Método de Pagamento</p>
      <p class="text-4xl text-white">{{ paymentoInformation.payment_method }}</p>
    </div>

    <div
      *ngIf="userPlanData.plan"
      class="bg-dark rounded-xl p-4 flex flex-col justify-between col-span-1"
    >
      <p class="text-2xl text-white">Armazenamento</p>

      <div class="flex justify-between items-center">
        <p class="font-medium text-lg text-white">
          <span>{{ userStorageData.store.used }}mb</span>
          /
          <span class="text-xs">{{ userStorageData.store.total }}mb</span>
        </p>

        <div class="w-full relative">
          <canvas id="storeChart" width="200" height="200"></canvas>
        </div>
      </div>
    </div>

    <div
      class="col-span-1 relative bg-dark rounded-xl p-4 flex flex-col text-white lg:row-span-1"
    >
      <p class="text-2xl font-bold">Notificações</p>
      <ng-scrollbar class="!max-h-80">
        <div
          class="flex flex-col items-start bg-primary rounded-md my-2 p-4"
          *ngFor="let notification of notifications"
        >
          <p class="text-xl font-bold">{{ notification.title }}</p>
          <p class="text-base">{{ notification.subtitle }}</p>
          <p class="text-sm font-light text-gray-100">
            {{ notification.message }}
          </p>
        </div>
      </ng-scrollbar>
    </div>
  </div>
</div>

<div *ngIf="!userPlanData" class="flex justify-center items-center">
  <app-loader></app-loader>
</div>
