<nav class="fixed top-0 z-40 w-full bg-darker">
  <div class="px-3 py-3 lg:px-5 lg:pl-3">
    <div class="flex items-center justify-between">
      <div class="flex items-center justify-start rtl:justify-end">
        <button
          data-drawer-target="logo-sidebar"
          data-drawer-toggle="logo-sidebar"
          data-drawer-show="logo-sidebar"
          (click)="toggleSidebar()"
          aria-controls="logo-sidebar"
          type="button"
          class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        >
          <svg
            class="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              fill-rule="evenodd"
              d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
            ></path>
          </svg>
        </button>
      </div>
      <div class="flex items-center">
        <div class="items-center gap-x-2 md:flex mr-2">
          <div>
            <button class="text-white hover:text-success">
              <a [href]="env.baseApp" type="button">
                Voltar para à Navegação
              </a>
            </button>
          </div>

          <div
            class="hidden md:flex mr-2 p-2 border-0 border-white-400 rounded-lg bg-dark tooltip tooltip-bottom"
            data-tip="Em breve..."
          >
            <button class="text-white hover:text-success">
              <a> Reportar Bug </a>
            </button>
          </div>
        </div>

        <!-- Inicio dropdown menu-->
        <div class="dropdown dropdown-bottom dropdown-left">
          <div tabindex="0" role="button">
            <img
              class="w-8 h-8 rounded-full bg-white"
              src="{{ userData.photoUrl }}"
              alt="user photo"
            />
          </div>

          <ul
            tabindex="0"
            class="dropdown-content menu bg-white divide-y divide-gray-100 rounded z-[1] w-52 py-1 shadow"
          >
            <div class="px-4 py-3" role="none">
              <p class="text-sm font-medium text-gray-900" role="none">
                {{ userData.name }}
              </p>
            </div>

            <li>
              <a
                routerLink="/"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                role="menuitem"
                >Início</a
              >
            </li>
            <li>
              <a
                routerLink="/informacoes"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                role="menuitem"
                >Informações</a
              >
            </li>
            <li>
              <form
                (submit)="submit($event)"
                class="block text-sm p-0 text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <button
                  class="w-full px-4 py-2 flex justify-start"
                  type="submit"
                >
                  Sair
                </button>
              </form>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>

<aside
  id="logo-sidebar"
  class="flex flex-col justify-between fixed rounded-e-2xl top-0 left-0 z-50 w-64 h-screen py-10 transition-transform -translate-x-full bg-black sm:translate-x-0"
  [class.translate-x-full]="!isSidebarOpen"
  [class.hidden]="!isSidebarOpen"
  [class.translate-x-0]="isSidebarOpen"
  aria-label="Sidebar"
>
  <div class="relative h-full px-3 pb-4 bg-black">
    <div class="flex items-center ps-2.5 mb-5 space-x-4">
      <img
        src="{{ userData.photoUrl }}"
        alt="Vigente logo"
        class="w-10 h-10 rounded-full bg-white"
      />

      <span class="text-white">{{ userData.name?.split(" ")?.[0] }}</span>
      <button
        (click)="toggleSidebar()"
        class="text-white flex items-center absolute right-0"
      >
        <svg
          class="w-6 h-6 border-2 rounded-full border-success bg-dark text-white hover:text-success"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m15 19-7-7 7-7"
          />
        </svg>
      </button>
    </div>

    <div class="h-[1px] bg-gradient-to-r from-black via-dark to-black"></div>
    <ul class="space-y-2 font-medium">
      <li>
        <a
          routerLinkActive="bg-success bg-gradient-to-b from-green-950 via-green-900 to-green-950"
          routerLink="/"
          [routerLinkActiveOptions]="{ exact: true }"
          class="flex items-center px-2 py-3 text-gray-300 rounded-lg hover:bg-gray-800 hover:text-gray-50 group"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
            />
          </svg>

          <span class="ms-3">Início</span>
        </a>
      </li>

      <li>
        <a
          routerLinkActive="bg-success bg-gradient-to-b from-green-950 via-green-900 to-green-950"
          routerLink="/informacoes"
          class="flex items-center px-2 py-3 text-gray-300 rounded-lg hover:bg-gray-800 hover:text-gray-50 group"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
            />
          </svg>

          <span class="ms-3">Informações</span>
        </a>
      </li>

      <li>
        <a
          routerLinkActive="bg-success bg-gradient-to-b from-green-950 via-green-900 to-green-950"
          routerLink="cards"
          class="flex items-center px-2 py-3 text-gray-300 rounded-lg hover:bg-gray-800 hover:text-gray-50 group"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3"
            />
          </svg>

          <span class="ms-3">Meus cartões</span>
        </a>
      </li>
    </ul>
  </div>

  <!--<div class="px-4">
    <div
      class="border border-primary rounded-2xl p-4 flex flex-col items-center"
    >
      <h6 class="text-white text-lg text-center">
        Conheça o Diagnóstico de Imóvel Rural
      </h6>
      <span class="text-gray-400 text-sm text-center mb-6"
        >Faça um DIR agora mesmo!</span
      >

      <button class="btn btn-sm btn-success btn-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
        Fazer um DIR
      </button>
    </div>
  </div>-->
</aside>

<div class="p-4 mt-14 lg:py-4 lg:px-40 sm:ml-64">
  <router-outlet></router-outlet>
</div>
