/**
 * Checks if cpf is a string, has 11 numeric digits and passes the cpf check.
 */
export function isValidCPF(cpf: string) {
  cpf = cpf.replace(/[^\d]+/g, '');

  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;

  const formattedCPF: number[] = cpf.split('').map((el: string) => +el);

  const rest = (count: number) =>
    ((formattedCPF
      .slice(0, count - 12)
      .reduce((sum, el, index) => sum + el * (count - index), 0) *
      10) %
      11) %
    10;

  return rest(10) === formattedCPF[9] && rest(11) === formattedCPF[10];
}

/**
 * Checks if cnpj is a string, has 14 numeric digits and passes the cnpj validator.
 */
export function isValidCNPJ(cnpj: string) {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj.length !== 14 || !!cnpj.match(/(\d)\1{13}/)) return false;

  const formattedCNPJ: number[] = cnpj.split('').map((el: string) => +el);

  const [firstDigit, secondDigit] = formattedCNPJ.slice(12);

  const validator = (number: number) => {
    const slice = formattedCNPJ.slice(0, number);
    let factor = number - 7;
    let sum = 0;

    for (let i = number; i >= 1; i--) {
      const n = slice[number - i];
      sum += n * factor--;
      if (factor < 2) factor = 9;
    }

    const result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
  };

  return !(validator(12) !== firstDigit || validator(13) !== secondDigit);
}

/**
 * Checks if the cep code is a string and if it has 8 numeric digits.
 */
export function isValidCEP(cep: string) {
  cep = cep.replace(/[^\d]+/g, '');

  return cep.length === 8;
}

/**
 * Checks if the card numbers are a string and if it has 12 numeric digits.
 */
export function isValidCardNumbers(numbers: string) {
  numbers = numbers.replace(/[^\d]+/g, '');

  return numbers.length === 16;
}

/**
 * Checks if the cvc is a string and if it has 3 numeric digits.
 */
export function isValidCVV(cvc: string) {
  cvc = cvc.replace(/[^\d]+/g, '');

  return cvc.length === 3 || cvc.length === 4;
}
