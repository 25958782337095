<div class="w-full min-h-svh flex justify-center items-center">
  <div
    class="max-w-xl w-full flex flex-col items-center justify-center py-8 px-16 bg-gradient-to-br from-[#324138] to-[#262B28] rounded-xl"
  >
    <img src="../../../assets/images/logo_white.png" alt="" />
    <h1 class="text-4xl font-medium text-white">Finalize seu cadastro</h1>

    <form class="w-full" [formGroup]="afterSignUpForm" (ngSubmit)="onSubmit()">
      <input
        placeholder="Nome Completo"
        formControlName="name"
        type="text"
        class="input my-4 w-full"
        required
      />

      <input
        formControlName="email"
        type="email"
        class="input my-4 w-full input-disabled"
        required
      />

      <input
        placeholder="Celular"
        formControlName="phone"
        type="text"
        class="input my-4 w-full"
        mask="(00) 00000-0000"
        required
      />

      <button
        class="btn btn-success btn-block mt-4"
        [disabled]="afterSignUpForm.invalid || isLoading"
      >
        Cadastrar
      </button>
    </form>
  </div>
</div>
