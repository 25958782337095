import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { from, Observable, switchMap } from 'rxjs';

@Injectable()
export class tokenInterceptor implements HttpInterceptor {
  constructor(protected readonly keycloak: KeycloakService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log('object');
    return from(this.keycloak.getToken()).pipe(
      switchMap((token) => {
        const newReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
        return next.handle(newReq);
      })
    );
  }
}
