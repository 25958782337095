import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NotificationService } from '../services/notification.service';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
  constructor(private readonly notificationsService: NotificationService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        const message =
          typeof error.error.message === 'string'
            ? error.error.message
            : (error.error.message as any).message;
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${message}`;

          this.notificationsService.sendData({
            type: 'error',
            message,
            visible: true,
          });
        } else {
          errorMsg = `Server Error: ${error.error.message}`;
          this.notificationsService.sendData({
            type: 'error',
            message,
            visible: true,
          });
        }

        return throwError(() => new Error(errorMsg));
      })
    );
  }
}
