import { Component, OnInit } from '@angular/core';
import { CardInformationsFormComponent } from '../card-informations-form/card-informations-form.component';
import { Router } from '@angular/router';
import { UserService } from '../../../../services/user.service';
import { LoaderComponent } from '../../../loader/loader.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-add-card',
  standalone: true,
  imports: [CardInformationsFormComponent, LoaderComponent, CommonModule],
  templateUrl: './add-card.component.html',
  styleUrl: './add-card.component.scss',
})
export class AddCardComponent implements OnInit {
  public isLoading: boolean = true;
  constructor(
    private readonly router: Router,
    private readonly userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService.userHasPagarmeClient().subscribe({
      next: (res) => {
        if (!res) return this.router.navigate(['/cobranca/information']);

        this.isLoading = false;
        return;
      },
    });
  }
  goBack() {
    this.router.navigate(['/cartoes']);
  }
}
