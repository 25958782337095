export const environment = {
  production: false,
  apiUrl: 'https://api.novo.homolog.vigente.com.br',
  auth_realm: 'homolog',
  auth_url: 'https://auth-novo.vigente.com.br',
  auth_url_realms: 'https://auth-novo.vigente.com.br/realms/homolog',
  auth_clientId: 'perfil-client',
  baseAuth: 'https://site.develop.vigente.com.br',
  basePortal: 'https://vigente.com.br',
  baseAdmin: 'https://admin.homolog.vigente.com.br',
  baseApp: 'https://app.homolog.vigente.com.br',
};
