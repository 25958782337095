import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { tokenInterceptor } from './interceptors/token.interceptor';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';
import { ErrorCatchingInterceptor } from './interceptors/error.interceptor';
import { provideEnvironmentNgxMask } from 'ngx-mask';

function initializeKeycloak(keycloak: KeycloakService) {
  return async () => {
    await new Promise<void>((resolve, reject) => {
      try {
        void keycloak
          .init({
            config: {
              realm: environment.auth_realm,
              url: environment.auth_url,
              clientId: environment.auth_clientId,
            },
            initOptions: {
              onLoad: 'login-required',
              checkLoginIframe: false,
            },
            enableBearerInterceptor: true,
            bearerExcludedUrls: ['/assets', '/clients/public'],
          })
          .then(() => {
            const keycloakAuth = keycloak.getKeycloakInstance();

            const updateToken = async (): Promise<string> => {
              return await keycloakAuth
                .updateToken(5)
                .then(() => keycloakAuth.token)
                .catch((e) => e);
            };
            const login = async (): Promise<void> => {
              return await keycloakAuth
                .login()
                .then(() => {})
                .catch((e) => e);
            };

            keycloakAuth.onTokenExpired = () => {
              if (keycloakAuth.refreshToken) {
                void updateToken();
              } else {
                void login();
              }
            };

            resolve();
          });
      } catch (error) {
        reject(error);
      }
    });
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideCharts(withDefaultRegisterables()),
    provideHttpClient(withInterceptorsFromDi()),
    provideEnvironmentNgxMask(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: tokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true,
    },
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
  ],
};
